<template>
    <div>
        <h3>Create Users</h3>
        <hr>
        <b-container>
            <b-row align-h="center" cols="2">
            <b-card header="New User">
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-form-group
                        id="firstname"
                        label="First Name:"
                        label-for="firstname"
                    >
                        <b-form-input
                            id="firstname"
                            v-model="form.firstname"
                            placeholder="Enter First Name"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="lastname"
                        label="Last Name:"
                        label-for="lastname"
                    >
                        <b-form-input
                            id="lastname"
                            v-model="form.lastname"
                            placeholder="Enter Last Name"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="username"
                        label="Username:"
                        label-for="username"
                    >
                        <b-form-input
                            id="username"
                            v-model="form.username"
                            placeholder="Enter Username"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="email"
                        label="Email address:"
                        label-for="email"
                    >
                        <b-form-input
                            id="email"
                            v-model="form.email"
                            type="email"
                            placeholder="Enter email"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="role"
                        label="Role:"
                        label-for="role"
                    >
                        <b-form-select
                            id="role"
                            v-model="form.role"
                            :options="roleOptions"
                        >
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                        id="pin"
                        label="User PIN:"
                        label-for="pin"
                    >
                        <b-form-input
                            id="pin"
                            v-model="form.adminPin"
                            type="number"
                            :formatter="pinFormatter"
                            min="111111"
                            max="999999"
                            placeholder="Enter PIN"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="company"
                        label="Company:"
                        label-for="company"
                    >
                        <b-form-select
                            id="company"
                            v-model="form.company"
                            :options="compOptions"
                            :disabled="compSelDisable"
                        >
                        </b-form-select>
                    </b-form-group>
                    <div class="text-center">
                        <b-button type="submit" class="ml-1 mr-1" variant="primary">Create</b-button>
                        <b-button type="reset" class="ml-1 mr-1" variant="danger">Clear</b-button>
                    </div>
                </b-form>
              <div class="text-center">
                <hr>
                <b-button @click="doneCreate" class="ml-1 mr-1" variant="success">Done</b-button>
              </div>
            </b-card>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import roles from '@/roles';
export default {
    name: "CreateUser",
    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                username: '',
                email: '',
                company: 0,
                role: 1,
                adminPin: null
            },
            compSelDisable: false
        }
    },
    mounted() {
        this.form.company = this.$store.getters.company;
        if (this.$store.getters.role < roles.roles.superUser) {
            this.compSelDisable = true;
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.$store.dispatch('createUser', JSON.stringify(this.form)).then(() => {
                this.$bvToast.toast('User Has Been Created', {
                    title: 'User Created',
                    variant: 'success',
                    solid: true
                });
                this.resetForm();
            }).catch(() => {
                this.$bvToast.toast('User Creation Error, Ensure the username does not already exist', {
                    title: 'User Not Created',
                    variant: 'danger',
                    solid: true
                });
            });
        },
        pinFormatter(value) {
            return value.substr(0, 6);
        },
        doneCreate() {
          this.$router.back();
        },
        onReset(event) {
            event.preventDefault()
            this.resetForm();
            this.show = false
            this.$nextTick(() => {
                this.show = true
            });
        },
        resetForm()
        {
            // Reset our form values
            this.form.email = '';
            this.form.adminPin = null;
            this.form.firstname = '';
            this.form.lastname = '';
            this.form.username = '';
        }
    },
    computed: {
        compOptions() {
            const cList = this.$store.getters.compList;
            let cNameList = [];
            if (cList !== null) {
                for (let i = 0; i < cList.length; i++) {
                    let comp = {
                        text: cList[i].name,
                        value: i
                    };
                    cNameList.push(comp);
                }
            }
            return cNameList;
        },
        roleOptions() {
            let roleList = [];

            roleList.push({value: 0, text: "Guest User"});
            roleList.push({value: 1, text: "User"});
            if (this.$store.getters.role >= roles.roles.technician)
                roleList.push({value: 2, text: "Tech"});

            if (this.$store.getters.role >= roles.roles.accountManager)
                roleList.push({value: 3, text: "Manager"});

            if (this.$store.getters.role >= roles.roles.admin)
                roleList.push({value: 4, text: "Admin"});

            if (this.$store.getters.role >= roles.roles.superUser)
                roleList.push({value: 5, text: "Super"});

            return roleList;
        }
    }
}
</script>

<style scoped>

</style>
